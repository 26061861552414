<template>
  <v-main class="main-container">
    <v-container>
      <div style="max-width: 800px">
        <div class="page" id="page-1">
          <h1>MORTGAGE APPLICATION</h1>
          <h4>Prepared for:</h4>
          <p>
            Name:

            <span
              v-for="(applicant, index) in applicants"
              :key="index"
            >
              {{ applicant }}
              <span v-if="index + 1 !== applicants.length">&</span>
            </span>
          </p>

          <h4>Produced by:</h4>
          <p>
            Financial Adviser: {{ userInfo?.profile?.fullName }}<br>
            Registration: {{ userInfo?.profile?.registration }}<br>
            Email: {{ userInfo?.auth?.email }}<br>
            Phone: {{ userInfo?.auth?.phone }}<br>
          </p>

          <h4>On behalf of:</h4>
          <p>
            Business Name: {{ userInfo?.profile?.businessName }}<br>
            Registration: {{ userInfo?.profile?.businessRegistration }}<br>
            Email: {{ userInfo?.profile?.businessEmail }}<br>
          </p>

          <h4>Date prepared: {{ new Date() }}</h4>
          <br><br><br><br><br><br>
          <hr>
          <p>I am a financial adviser, providing financial advice on behalf of
            {{ userInfo?.profile?.businessName }} ({{ userInfo?.profile?.businessRegistration }}),
            who is
            authorised to provide regulated financial advice under the licence held by
            {{ userInfo?.profile?.businessName }} ({{ userInfo?.profile?.businessRegistration }}).
          </p>
        </div>
        <div class="page-break"></div>

        <!-- Loan Application Summary -->
        <div class="page">
          <h2>Loan Application Summary</h2>
          <h3>
            Borrowers:
            <span
              v-for="(applicant, index) in applicants"
              :key="index"
            >
              {{ applicant }}
              <span v-if="index+1 !== applicants.length">&</span>
            </span>
          </h3>
          <p>
            Primary Purpose:
            <span
              v-for="(primaryPurpose, index) in primaryPurposes"
              :key="primaryPurpose"
            >
             {{ primaryPurpose }}
             <span v-if="index+1 !== primaryPurposes.length">&</span>
            </span>
          </p>
          <h3>Applicants:</h3>
          <p>Number of Applicants: {{ applicants.length }}<br>
            Number of Dependants: {{ dependants ? dependants.length : '-' }}
          </p>
          <h3>Lending requirements</h3>
          <table>
            <tr>
              <th>Total Security to the bank:</th>
              <th>${{ securityValue.toLocaleString() }}</th>
            </tr>

            <tr>
              <td><i>(new)</i> {{ proposedHouse }}</td>
              <td>${{ parseInt(proposedHousePrice || 0, 10).toLocaleString() }}
              </td>
            </tr>

            <tr v-for="house in existingHouses" :key="house.id">
              <td>{{ house?.address }}</td>
              <td>${{ parseInt(house?.value, 10).toLocaleString() }}
              </td>
            </tr>

            <tr>
              <th>Deposit</th>
              <th>${{ parseInt(depositValue, 10).toLocaleString() }}
              </th>
            </tr>

            <tr v-for="deposit in depositSource" :key="deposit.id">
              <td>{{ deposit.source }}</td>
              <td>${{parseInt(deposit.amount, 10).toLocaleString() }}
              </td>
            </tr>

            <tr>
              <th>Lending Required</th>
              <th>${{ lendingRequired }}</th>
            </tr>

            <tr>
              <th>LVR</th>
              <th>
                {{ LVR }}%
              </th>
            </tr>
          </table>
        </div>
        <div class="page-break"></div>

        <!-- Retirement & Other Financial Consideration -->
        <div class="page">
          <h2>Lending notes</h2>
          <h3>Purpose:</h3>
          <p>
            <template v-if="APPLICATION?.data?.lending_notes?.purpose">
              {{ this.APPLICATION?.data?.lending_notes?.purpose }}
            </template>
            <template v-else>-</template>
          </p>
          <h3>Client Background and Character:</h3>
          <p>
             <template v-if="APPLICATION?.data?.lending_notes?.backgrounds">
              {{ this.APPLICATION?.data?.lending_notes?.backgrounds }}
            </template>
            <template v-else>-</template>
          </p>
          <h3>Other notes:</h3>
          <p>
            <template v-if="APPLICATION?.data?.lending_notes?.other">
              {{ this.APPLICATION?.data?.lending_notes?.other }}
            </template>
            <template v-else>-</template>
          </p>
        </div>
        <div class="page-break"></div>

        <!-- Applicants -->
        <div class="page">
          <h2>Applicants</h2>
          <div v-for="applicant in activeSubApplications" :key="applicant.id">
            <h3>Applicant ID:{{ applicant.id }}</h3>
            <ul>
              <li>
                First name: {{ applicant?.data?.personal_details?.first_name }}
              </li>

              <li>
                Middle name: {{ applicant?.data?.personal_details?.middle_name }}
              </li>

              <li>
                Preferred name: {{ applicant?.data?.personal_details?.preffered_name }}
              </li>

              <li>
                Last name: {{ applicant?.data?.personal_details?.last_name }}
              </li>

              <li>
                Date of Birth: {{ applicant?.data?.personal_details?.birth_date }}
              </li>

              <li>
                Country of Birth: {{ applicant?.data?.personal_details?.country_of_birth }}
              </li>

              <li>
                Residency status: {{ applicant?.data?.personal_details?.nz_residency_status }}
              </li>

              <li>
                Marital Status:
                {{ applicant?.data?.personal_details?.marital_status }}
              </li>

              <li>
                Phone number: {{ applicant?.data?.personal_details?.phone }}
              </li>

              <li>
                Email: {{ applicant?.data?.personal_details?.email }}
              </li>
            </ul>
            <br>
            <template v-if="applicant?.data?.address_history.length">
              <h4>Address History</h4>
              <table>
                <tr>
                  <th>Address</th>
                  <th>Status</th>
                  <th>Period</th>
                </tr>
                  <tr
                    v-for="(addressRecord, index) in applicant?.data?.address_history"
                    :key="index">
                    <td>{{ addressRecord.address }}</td>
                    <td>{{ addressRecord.arrangement }}</td>
                    <td>
                      {{ addressRecord.move_in }} - {{ addressRecord.move_out || 'Current'}}
                    </td>
                  </tr>
              </table>
            </template>
            <br>
            <template v-if="applicant?.data?.employments.length">
              <h4>Employments Details</h4>
              <table>
                <tr>
                  <th>Type</th>
                  <th>Role</th>
                  <th>Employment Basis</th>
                  <th>Employer Name</th>
                  <th>Period</th>
                </tr>
                <tr
                  v-for="(employment, index) in applicant?.data?.employments"
                  :key="index">
                  <td>{{ employment.income_source || '-' }}</td>
                  <td>{{ employment.role || '-' }}</td>
                  <td>{{ employment.basis || '-' }}</td>
                  <td>{{ employment.employer || '-' }}</td>
                  <td>
                    {{ employment.start_date }} -
                    <template v-if="employment.current">Current</template>
                    <template v-else>{{ employment.end_date }}</template>
                  </td>
                </tr>
              </table>
            </template>
            <br>
          </div>
        </div>
        <div class="page-break"></div>

        <div class="page">
          <h2>Statement of assets and liabilities:</h2>
          <h3>Mortgages:</h3>
          <table v-if="APPLICATION?.data?.liabilities?.mortgages.length">
            <tr>
              <th>Ownership</th>
              <th>Creditor</th>
              <th>Balance, $</th>
              <th>Repayment, $</th>
              <th>Repayment Period</th>
              <th>Loan Term Ends</th>
              <th>Refinance/Security to bank</th>
            </tr>
            <tr
              v-for="(mortgage, index) in APPLICATION?.data?.liabilities?.mortgages"
              :key="index">
              <td>{{ mortgage.ownership || '-' }}</td>
              <td>{{ mortgage.creditor || '-' }}</td>
              <td>{{ Math.round(mortgage.balance).toLocaleString() || '-' }}</td>
              <td>{{ Math.round(mortgage.repayment).toLocaleString() || '-' }}</td>
              <td>{{ mortgage.repayment_period || '-' }}</td>
              <td>{{ mortgage.terms || '-' }}</td>
              <td>{{ mortgage.refianance || '-' }}</td>
            </tr>
          </table>
          <p v-else>-</p>
          <h3>Existing Real Estate Assets:</h3>
          <table v-if="APPLICATION?.data?.real_estate_assets.length">
            <tr>
              <th>Ownership</th>
              <th>Address</th>
              <th>Primary Purpose</th>
              <th>Value, NZD, $</th>
              <th>Value Basis</th>
              <th>Security to bank?</th>
            </tr>
            <tr
              v-for="(estate, index) in APPLICATION?.data?.real_estate_assets"
              :key="index">
              <td>{{ estate.ownership || '-' }}</td>
              <td>{{ estate.address || '-' }}</td>
              <td>{{ estate.purpose || '-' }}</td>
              <td>{{ Math.round(estate.value).toLocaleString() || '-' }}</td>
              <td>{{ estate.value_basis || '-' }}</td>
              <td>{{ estate.security || '-' }}</td>
            </tr>
          </table>
          <p v-else>-</p>
          <h3>Other Assets:</h3>
          <table v-if="APPLICATION?.data?.other_assets.length">
            <tr>
              <th>Type</th>
              <th>Ownership</th>
              <th>Description</th>
              <th>Value, NZD, $</th>
            </tr>
            <tr v-for="(asset, index) in APPLICATION?.data?.other_assets" :key="index">
              <td>{{ asset.type || '-' }}</td>
              <td>{{ asset.ownership || '-' }}</td>
              <td>{{ asset.description || '-' }}</td>
              <td>{{ asset.value || '-' }}</td>
            </tr>
          </table>
          <p v-else>-</p>
          <h3>Liabilities:</h3>
          <table v-if="APPLICATION?.data?.liabilities?.items.length">
            <tr>
              <th>Type</th>
              <th>Ownership</th>
              <th>Creditor</th>
              <th>Balance, $</th>
              <th>Repayment Amount, $</th>
              <th>Repayment Period</th>
            </tr>
            <tr
              v-for="(liability, index) in APPLICATION?.data?.liabilities?.items"
              :key="index"
            >
              <td>{{ liability.type || '-' }}</td>
              <td>{{ liability.ownership || '-' }}</td>
              <td>{{ liability.creditor || '-' }}</td>
              <td>{{ liability.balance || '-' }}</td>
              <td>{{ liability.repayment_amount || '-' }}</td>
              <td>{{ liability.repayment_period || '-' }}</td>
            </tr>
          </table>
          <p v-else>-</p>
        </div>
        <div class="page-break"></div>

        <div class="page-break"></div>
        <!-- Income and Expenses -->
        <div class="page">
          <h2>Income and Expenses</h2>
          <h3>Employee income:</h3>
          <template v-if="APPLICATION?.data?.incomes?.employee.length">
            <table>
              <tr>
                <th>Reference</th>
                <th>Income, $</th>
                <th>Income Frequency</th>
              </tr>
              <tr
                v-for="(employee, index) in APPLICATION?.data?.incomes?.employee"
                :key="index">
                <td>{{ employee.ref.name || '-' }}</td>
                <td>{{ Math.round(employee.income).toLocaleString() || '-' }}</td>
                <td>{{ employee.income_freq || '-' }}</td>
              </tr>
            </table>
            <br>
          </template>
          <p v-else>-</p>
          <h3>Rental Income:</h3>
          <template v-if="APPLICATION?.data?.incomes?.rental.length">
            <table>
              <tr>
                <th>Address</th>
                <th>Type</th>
                <th>Income, $</th>
                <th>Income Frequency</th>
              </tr>
              <tr
                v-for="(rental, index) in APPLICATION?.data?.incomes?.rental"
                :key="index">
                <td>{{ rental.address || '-' }}</td>
                <td>{{ rental.type || '-' }}</td>
                <td>{{ Math.round(rental.income).toLocaleString() || '-' }}</td>
                <td>{{ rental.income_freq || '-' }}</td>
              </tr>
            </table>
            <br>
          </template>
          <p v-else>-</p>
          <h3>Other net income:</h3>
          <template v-if="APPLICATION?.data?.incomes?.other.length">
            <table>
              <tr>
                <th>Description</th>
                <th>Income, $</th>
                <th>Income Frequency</th>
              </tr>
              <tr
                v-for="(other, index) in APPLICATION?.data?.incomes?.other"
                :key="index">
                <td>{{ other.description || '-' }}</td>
                <td>{{ Math.round(other.income).toLocaleString() || '-' }}</td>
                <td>{{ other.income_freq || '-' }}</td>
              </tr>
            </table>
            <br>
          </template>
          <p v-else>-</p>
          <h3>Living expenses:</h3>
          <table>
            <tr>
              <th>Description</th>
              <th>Per month, NZD</th>
            </tr>
            <tr v-for="exp in livingExpensesData?.expenses" :key="exp.id">
              <td>{{ exp?.description }}</td>
              <td>${{ valueMathFormatter(exp?.monthly) }}
              </td>
            </tr>
            <tr>
              <th>Total, per month</th>
              <th>$ {{ valueMathFormatter(livingExpensesData?.total) }}
              </th>
            </tr>
          </table>
        </div>
        <div class="page-break"></div>

        <div class="page" v-if="duringLoan">
        </div>
        <div class="page-break" v-if="duringLoan"></div>

        <div class="page">
          <h2>Retirement & Financial Consideration</h2>
          <h3>
            Are the borrowers aged 55 or over at application
            or 75 years or over when the Home Loan matures?
          </h3>
          Yes
          <h3>
            How do you intend to repay the loan?
          </h3>
          {{ repay }}
          <h3>
            Does the applicant plan or anticipate changes (other than retirement)
            to their future financial circumstances that could ADVERSELY
            impact their ability to repay the loan?
          </h3>
          {{ anticipate }}
          <br>
          {{ retirementComment }}
        </div>
        <div class="page-break"></div>

        <!-- Files -->
        <div class="page">
          <FileManagerPDF
           :application-id="$route.params.id"
          />
        </div>
        <div class="page-break"></div>
      </div>
    </v-container>
  </v-main>

</template>

<script>
import { mapGetters } from 'vuex';
import {
  getCurrentUser,
  getUserInfo,
} from '@/services/firebase';

import FileManagerPDF from '@/components/FileManagerPDF.vue';
import SummaryMixin from '@/mixins/SummaryMixin';
import { APPLICATION_STATUSES } from '../models/helpers/consts';

export default {
  name: 'ApplicationAdviserPrint',

  mixins: [SummaryMixin],

  data() {
    return {
      loading: false,

      subApps: [],

      userInfo: {
        profile: {},

        auth: {},
      },
    };
  },

  components: {
    FileManagerPDF,
  },

  computed: {
    ...mapGetters(['APPLICATION', 'SUB_APPLICATIONS']),

    liabilitiesExist() {
      const exist = Boolean(this.liabilities.liabilities.length > 0);

      return exist;
    },

    activeSubApplications() {
      const isActive = (application) => application.status !== APPLICATION_STATUSES.draft;

      return this.SUB_APPLICATIONS.filter(isActive);
    },

    applicants() {
      // todo: duplicate
      const getUserName = (application) => {
        const firstName = application?.data?.personal_details?.first_name;
        const lastName = application?.data?.personal_details?.last_name;

        return `${firstName} ${lastName}`;
      };

      const result = this.activeSubApplications?.map(getUserName);

      return result;
    },

    primaryPurposes() {
      const primaryPurposes = this.APPLICATION?.data?.funding_details?.lending_purpose;

      return primaryPurposes;
    },

    proposedHouse() {
      const proposedHouse = this.APPLICATION?.data?.funding_details?.property_address;

      return proposedHouse;
    },

    depositSource() {
      const depositSource = this.APPLICATION?.data?.funding_details?.deposit_source;

      return depositSource ?? [];
    },

    dependants() {
      const dependants = this.APPLICATION?.data?.dependants;

      return dependants;
    },

    duringLoan() {
      const duringLoan = this.APPLICATION?.data?.retirement?.during_loan;

      return duringLoan;
    },

    repay() {
      const repay = this.APPLICATION?.data?.retirement?.repay;

      return repay;
    },

    anticipate() {
      const anticipate = this.APPLICATION?.data?.retirement?.anticipate;

      return anticipate ? 'Yes' : 'No';
    },

    retirementComment() {
      const comment = this.APPLICATION?.data?.retirement?.comment;

      return comment;
    },
  },

  methods: {
    valueMathFormatter(value) {
      if (value) {
        const computedValue = Math.round(value).toLocaleString();
        return computedValue;
      }
      return 0;
    },
    countryToText(countryObject) {
      if (countryObject.name) {
        return countryObject.name;
      }

      return countryObject;
    },

    async loadUserInfo() {
      const userResponse = await getUserInfo(getCurrentUser().uid);

      this.userInfo = userResponse.data();
    },
  },

  async mounted() {
    await this.loadUserInfo();
  },
};
</script>

<style scoped>

.text-align-center {
  text-align: center;
}

.main-container {
  background-color: #EEE;
}

.page {
  padding: 30px;
  background-color: white;
  border-radius: 10px;
}

.page:last-child {
  margin-bottom: 3rem;
}

.page-break {
  height: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #EEE;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid gray;
}

tr, td {
  border: 1px solid gray;
}

td {
  padding: 5px;
}

th {
  padding: 5px;
  background-color: lightgrey;
  border: 1px solid gray;
}

h3 {
  margin-top: 1rem
}

@media print {

  .main-container {
    background-color: #FFF;
  }

  .page {
    padding: 0;
  }

  .page:last-child {
    margin-bottom: 0;
  }

  .page-break {
    page-break-before: always;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #FFF;
    height: 0;
  }
}
</style>
